<template>
    <el-dialog
            title="评审建议"
            :visible.sync="dialogVisible"
            width="600px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-row :gutter="20" v-show="dataTuype == 2 && showData == 1">
            <el-col :span="12">
                <div class="flex_l_t">
                    <div style="white-space: nowrap">捐赠人/提供者信息：</div>
                    <div>{{ setData.length ? setData[0].donors : '' }}</div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="flex_l_t">
                    <div style="white-space: nowrap">藏品总数：</div>
                    <div>{{ setData.length ? setData[0].collectionDatas ? setData[0].collectionDatas.length : 0 :0 }} 件</div>
                </div>
            </el-col>
        </el-row>
        <el-form :model="searchForm" ref="searcForm" label-width="120px" size="small" label-position="top">
            <el-form-item label="请填写评审建议" prop="overture" :rules="{required: true, message: '评审建议不能为空', trigger: 'blur'}">
                <el-input style="width: 100%" type="textarea" rows="4" v-model.trim="searchForm.overture" placeholder="请输入评审建议(限100字)" maxlength="100" clearable></el-input>
            </el-form-item>
        </el-form>
        <div class="text_center" style="margin-top: 20px">
            <el-button size="small" type="primary" @click="saveDate()" :loading="saveDateType" v-no-more-click>确定</el-button>
            <el-button size="small" @click="handleClose()">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>

export default {
    name: "reviewSuggestions",
    data(){
        return{
            dialogVisible:false,

            searchForm:{
                overture:'',
            },
            dataTuype:2,
            state:'',
            revdId:'',
            setData:[],
            url:this.api.collection.revd,
            methods:'post',
            showData:'',
            idName:'',
            saveDateType:false,
        }
    },

    methods:{
        //num 1为批量、2为单个，allData修改的数据Array， type 2驳回、3通过, revdId审核项(0登记， 1 总账 ，2辅助账) showData1显示征集审核信息
        init(num,allData,type,revdId,url,methods,showData,name){
            console.log(allData)
            this.dataTuype = num
            this.setData = allData
            this.state = type
            this.revdId = revdId
            if (url){
                this.url = url
            }
            if (methods){
                this.methods = methods
            }
            if (showData){
                this.showData = showData
            }
            if (name){
                this.idName = name
            }
            this.dialogVisible = true
        },

        //确定
        saveDate(){
            this.$refs.searcForm.validate((valid) => {
                let serchData
                if (this.idName == 'id'){
                    serchData = {
                        id:this.setData[0].id,
                        state:this.state,
                        suggest:this.searchForm.overture,
                    }
                }else {
                    let data = this.setData.map(item=>{ return item.id })
                    serchData = {
                        ids:data,
                        revdId:this.revdId,
                        state:this.state,
                        suggest:this.searchForm.overture,
                    }
                }
                if (valid) {
                    this.saveDateType = true
                    this.$axios(this.url, serchData, this.methods).then((res) => {
                        if (res.status) {
                            this.$message.success(`${this.state == 2 ? '驳回' : '通过'}成功`)
                            this.$emit('updataStatus')
                            this.handleClose()
                        }else {
                            this.$message.error(res.msg)
                        }
                        this.saveDateType = false
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        handleClose() {
            this.$refs.searcForm.resetFields();
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>