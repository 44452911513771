<template>
  <el-dialog
      title="审核记录"
      :visible.sync="dialogVisible"
      width="1200px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
    <review-steps :stepsList="stepsList" :steps="steps" :disabled="disabled"></review-steps>
    <el-form size="small"
             @keyup.enter.native="search(1)"
             :inline="true" ref="searchForm" :model="searchForm" style="margin-top: 15px"
             label-width="100px">
      <el-form-item prop="createBy" label="审核人：">
        <el-select v-model="searchForm.createBy" filterable placeholder="请选择" clearable>
          <el-option v-for="item in auditUserList" :key="item.userId" :label="item.name"
                     :value="item.userId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="processResult" label="审核结果：">
        <el-select v-model="searchForm.processResult" filterable placeholder="请选择" clearable>
          <el-option
              :key="item.value"
              :label="item.label"
              :value="item.value"
              v-for="item in processResultList">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="dataList"
        size="small"
        v-loading="loading"
        ref="multipleTable"
        height="200px"
        class="table"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="processResult" label="审核结果" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createBy" label="审核人" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.createBy ? scope.row.createBy : '系统' }}
        </template>
      </el-table-column>
      <el-table-column prop="processName" label="审核操作" show-overflow-tooltip></el-table-column>
      <el-table-column prop="processOpinion" label="审核意见" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createDate" label="审核时间" show-overflow-tooltip></el-table-column>
      <el-table-column prop="submitBy" label="提交人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="submitTime" label="提交时间" show-overflow-tooltip></el-table-column>
    </el-table>
    <div class="text_center">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="total"
          background
          layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <div class="text_center" style="margin-top: 20px">
      <el-button size="small" @click="handleClose()">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>

import ReviewSteps from "@/views/modules/collection/accounts/module/reviewSteps.vue";

export default {
  name: "record",
  components: {ReviewSteps},
  data() {
    return {
      id: '',
      dialogVisible: false,
      dataList: [],
      loading: false,
      searchForm: {
        createBy: '',
        processResult: '',
      },
      auditUserList: [],
      processResultList: [
        {
          label: '审核通过',
          value: '审核通过'
        },
        {
          label: '审核驳回',
          value: '审核驳回'
        },
      ],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      fechUrl: this.api.collection.collectionprocessinfoList,

      revdBusinessId:'',
      steps:0,
      disabled:false,
      stepsList:[],
    }
  },

  methods: {
    //type 0藏品登记
    init(id, url, revdBusinessId) {
      if (id) {
        this.id = id
      }
      if (url) {
        this.fechUrl = url
      }
      this.revdBusinessId = revdBusinessId
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs['searchForm'].resetFields()
        this.search(1)
        this.getReviewSteps()
      })
    },

    search(type) {
      if (type == 1) {
        this.pageNo = 1
      }
      this.loading = true
      this.$axios(this.fechUrl, {
        collectionDataId: this.id,
        current: this.pageNo,
        size: this.pageSize,
        ...this.searchForm,
      }, "post").then((data) => {
        if (data && data.status) {
          this.auditUserList = data.data.auditVOS
          this.dataList = data.data.collectionProcessInfoVOIPage.records;
          this.total = parseInt(data.data.collectionProcessInfoVOIPage.total);
          this.loading = false;
        }
      });
    },

    getReviewSteps(){
      this.$axios(this.api.collection.collectionrevdprocessList, {
        dataId: this.id,
        revdBusinessId:this.revdBusinessId,
      }, "get").then((data) => {
        if (data && data.status) {
          this.stepsList = data.data
          this.steps = this.stepsList.length
          let firstDta = 0
          //判断是否全是未开始的状态
          this.disabled = this.stepsList[0].complement == 1
          this.stepsList.forEach((item,index)=>{
            if (item.complement == 1 && !firstDta){ //找到当前进行到第几步
              firstDta++
              this.steps = index
              if (this.stepsList[index - 1].revdOperate == 2){ //判断当前进行的步骤是否被驳回
                this.$set(item,'status','wait')
              }
            } else {
              this.$set(item,'status','')
            }
          })
          console.log(this.stepsList)
        }
      });
    },

    // 重置
    reset() {
      this.$refs.searchForm.resetFields();
      this.search(1)
    },

    handleClose() {
      this.dialogVisible = false
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.search();
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.search();
    },
  },
}
</script>

<style scoped>

</style>